import React from "react";
import { withAuthenticator } from "aws-amplify-react";
import MyTheme from "../../../../../../shared_ui/layouts/authTheme";
import { navigate } from "gatsby";

class LoginPage extends React.Component {
  componentDidMount() {
    navigate("/");
  }

  render() {
    return <p>You have logged in!</p>;
  }
}

export default withAuthenticator(LoginPage, false, [], null, MyTheme);
